import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WithdrawalProductType } from '@generated/generatedEntities';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

@Component({
  selector: 'app-license',
  //standalone: true,
  //imports: [],
  templateUrl: './license.component.html',
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['insufficient-rights'],
    },
  ],
})
export class LicenseComponent {
  private router: Router = inject(Router);
  module: WithdrawalProductType[] = this.router.getCurrentNavigation()!.extras!.state!['data'].module;
}
