<div *transloco="let t">
  <app-dynamic-image [mode]="'CONTENT_ROW'">
    <div class="content-row-container">
      <div style="margin-bottom: 25px">
        <h1 class="bannerTitle" style="white-space: nowrap">
          {{ t("insufficientRights.general.title") }}
        </h1>
      </div>
    </div>
  </app-dynamic-image>

  <div class="flexBoxOuter" style="margin: 33px">
    <div class="flexBoxInner">
      <p>
        {{ t("insufficientRights.license.text1.text.a") }}
        @for (wpt of module; track wpt; let last = $last; let i = $index) {
          <span>{{ t("prices.module." + wpt) }}</span>
          @if (!last) {
            <span>,</span>
          }
        }
        {{ t("insufficientRights.license.text1.text.b") }}
        <a routerLink="/billing"> {{ t("insufficientRights.license.text3") }}</a>
        {{ t("insufficientRights.license.text1.text.c") }}
      </p>

      <p>{{ t("insufficientRights.license.text2") }}</p>
    </div>
  </div>
</div>
